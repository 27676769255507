import axios from 'axios'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { auth, db } from '../../../../setup/firebase/firebaseConfig'

import { DBUserModel, UserModel } from '../models/UserModel'
import { doc, setDoc } from 'firebase/firestore'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

// Firebase Authentication
export function firebaseRegister(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password)
}

export function firebaseForgotPassword(email: string) {
  return sendPasswordResetEmail(auth, email)
}

export function updateFirebaseUserProfile(user, firstname: string, lastname: string) {
  return updateProfile(user, { displayName: `${firstname} ${lastname}` })
}

export async function addUserToDatabase(email: string, firstName: string, lastName: string) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await setDoc(doc(db, 'users', email), {
        email,
        firstName,
        lastName,
      })
      resolve('')
    } catch (e) {
      console.log(e)
      reject('Error occurred while adding user to firestore')
    }
  })
}

export async function changeUserDetails(email, userDetails: DBUserModel) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await setDoc(doc(db, 'users', email), userDetails)
      resolve('')
    } catch (e) {
      console.log(e)
      reject('Error occurred while adding user to firestore')
    }
  })
}

export function firebaseSignOut() {
  return signOut(auth)
}
