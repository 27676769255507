import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { shareClient } from '../clientsCRUD'
import { toast } from 'react-toastify'
// import { shallowEqual, useSelector } from 'react-redux'
// import { RootState } from '../../../../setup'
// import { UserModel } from '../../../modules/auth/models/UserModel'
import { FirebaseError } from '@firebase/util'
import { getFirebaseError } from '../../../../_metronic/helpers/FirebaseErrorHandler'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { ClientModel } from '../../../utils/models/clientModels'
import { styled } from '@mui/material/styles'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
})

interface ICreateAccount {
  membershipID: string
  clientID: string
}

const inits: ICreateAccount = {
  membershipID: '',
  clientID: '',
}

const createAppSchema = [
  Yup.object({
    membershipID: Yup.string().required().email().label('Membership ID'),
    clientID: Yup.string().required().label('Client ID'),
  }),
  Yup.object({}),
]

export function ShareClientModel({ getMyClient, clients }) {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [loading, setLoading] = useState<boolean>(false)

  // const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setLoading(true)
      try {
        await shareClient(values.membershipID, values.clientID)
        getMyClient()
        toast.success('Client shared successfully')
        document.getElementById('modal_share_client')?.click()
      } catch (e) {
        if (e instanceof FirebaseError) {
          toast.error(getFirebaseError(e))
        } else {
          toast.error('Error sending password reset error')
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='modal fade' id='modal_share_client' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Share Client</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Details</h3>

                      <div className='stepper-desc'>Name your App</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Submit Form</h3>

                      <div className='stepper-desc'>Define your app framework</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {({ values, setFieldValue }) => (
                    <Form className='form' noValidate id='kt_modal_create_app_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Membership ID (email)</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your unique app name'
                              ></i>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='membershipID'
                              placeholder=''
                            />
                            <div className='text-danger'>
                              {/*@ts-ignore*/}
                              <ErrorMessage name='membershipID' />
                            </div>
                          </div>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Client</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your unique app name'
                              ></i>
                            </label>
                            <Autocomplete
                              fullWidth
                              id='combo-box-demo'
                              options={clients}
                              onChange={(event, value: ClientModel | null, reason) => {
                                setFieldValue('clientID', value?.id || '')
                              }}
                              getOptionLabel={(option: ClientModel) =>
                                `${option.name} (${option.id})`
                              }
                              renderInput={(params) => (
                                <CssTextField
                                  className='form-control form-control-lg form-control-solid'
                                  sx={{
                                    '.MuiOutlinedInput-root': {
                                      height: '45.88px',
                                      padding: '0px 13.5px',
                                      fontFamily: 'inherit',
                                    },
                                  }}
                                  {...params}
                                />
                              )}
                            />
                            <div className='text-danger'>
                              {/*@ts-ignore*/}
                              <ErrorMessage name='clientID' />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div>
                          <div>Membership Id :-{values.membershipID}</div>
                          <div>Client ID :-{values.clientID}</div>
                        </div>
                      </div>

                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button
                            type='submit'
                            className={`${loading && 'disabled'} btn btn-lg btn-primary me-3`}
                            style={{ minWidth: '140px' }}
                          >
                            {!loading ? (
                              <span className='indicator-label'>
                                {stepper.current?.currentStepIndex !==
                                  stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-3 ms-2 me-0'
                                />
                              </span>
                            ) : (
                              <div className='spinner-grow-sm spinner-border' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
