import { FirebaseError } from '@firebase/util'

export function getFirebaseError(error: FirebaseError) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'Email Already Exist'
    case 'auth/weak-password':
      return 'Weak password'
    case 'auth/wrong-password':
      return 'Wrong password, please try again.'
    case 'auth/user-not-found':
      return 'User not found'
    case 'auth/too-many-requests':
      return 'Access to this account has been temporarily disabled due to many failed login attempts.'
    case 'not-found':
      return 'Invalid Membership ID'
    default:
      return 'Unknown Error'
  }
}
