import axios from 'axios'

const api = axios.create({
  baseURL: 'https://ethereum-api-7gss2kkg6q-uc.a.run.app',
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export async function apiGetAccountAssets(address: string, chainId: string) {
  const response = await api.get(`/account-assets?address=${address}&chainId=${chainId}`)
  const { result } = response.data
  return result
}

export async function apiGetAccountTransactions(address: string, chainId: string) {
  const response = await api.get(`/account-transactions?address=${address}&chainId=${chainId}`)
  const { result } = response.data
  return result
}

export const apiGetAccountNonce = async (address: string, chainId: string) => {
  const response = await api.get(`/account-nonce?address=${address}&chainId=${chainId}`)
  const { result } = response.data
  return result
}

export const apiGetGasPrices = async () => {
  const response = await api.get(`/gas-prices`)
  const { result } = response.data
  return result
}
