import { ContractModel, TransactionModel } from '../../utils/models/clientModels'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../setup/firebase/firebaseConfig'
import axios from 'axios'
import { mapDocToContract } from '../../utils/Mappers/clientMappers'

const mapDocToTransaction = (doc: any): TransactionModel => {
  return {
    timeStamp: doc?.timeStamp,
    from: doc?.from,
    to: doc?.to,
    value: doc?.value || '',
    gas: doc?.gas,
    gasPrice: doc?.gasPrice,
    gasUsed: doc?.gasUsed,
    hash: doc?.hash || '',
    tokenId: doc?.tokenID || '',
    methodId: doc?.methodId || '',
  }
}

export async function getContract(contractId: string) {
  return new Promise<ContractModel>(async (resolve, reject) => {
    try {
      const contractsRef = doc(db, 'contracts', contractId)
      const contractSnap = await getDoc(contractsRef)
      resolve(mapDocToContract(contractSnap))
    } catch (e) {
      reject('Unknown Error')
    }
  })
}

export async function getResentTransactions(address: string) {
  return new Promise<TransactionModel[]>(async (resolve, reject) => {
    let contracts: TransactionModel[] = []
    await axios
      .get('https://api-rinkeby.etherscan.io/api', {
        params: {
          module: 'account',
          action: 'txlist',
          address: address,
          sort: 'desc',
          startblock: 0,
          endblock: 99999999,
          page: 1,
          offset: 10,
          apikey: process.env.REACT_APP_ETHERSCAN_API_KEY,
        },
      })
      .then(({ data }) => {
        contracts = data.result.map((el) => mapDocToTransaction(el))
        resolve(contracts)
      })
      .catch((err) => {
        console.log(err)
        reject('Unknown Error')
      })
  })
}

export async function getRecentTokenTransactions(contractAddress: string) {
  return new Promise<TransactionModel[]>(async (resolve, reject) => {
    let contracts: TransactionModel[] = []
    await axios
      .get('https://api-rinkeby.etherscan.io/api', {
        params: {
          module: 'account',
          action: 'tokennfttx',
          contractaddress: contractAddress,
          sort: 'desc',
          startblock: 0,
          endblock: 99999999,
          page: 1,
          offset: 10,
          apikey: process.env.REACT_APP_ETHERSCAN_API_KEY,
        },
      })
      .then(({ data }) => {
        console.log('transaction data', data.result)
        contracts = data.result.map((el) => {
            console.log('THE EL', el)
            return mapDocToTransaction(el)
          }
        )
        console.log('contracts data', contracts)
        resolve(contracts)
      })
      .catch((err) => {
        console.log(err)
        reject('Unknown Error')
      })
  })
}
