import supportedChains from './chains'
import { apiGetGasPrices, apiGetAccountNonce } from './api'
import { convertAmountToRawNumber, convertStringToHex } from './bignumber'

export function refreshPage() {
  window.location.reload()
}

export function sanitizeHex(hex) {
  hex = hex.substring(0, 2) === '0x' ? hex.substring(2) : hex

  if (hex === '') {
    return ''
  }

  hex = hex.length % 2 !== 0 ? '0' + hex : hex
  return '0x' + hex
}

export function getChainData(chainId) {
  const chainData = supportedChains.filter((chain) => chain.chain_id === chainId)[0]

  if (!chainData) {
    throw new Error('ChainId missing or not supported')
  }

  const API_KEY = process.env.REACT_APP_INFURA_ID

  if (
    chainData.rpc_url.includes('infura.io') &&
    chainData.rpc_url.includes('%API_KEY%') &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)
    return {
      ...chainData,
      rpc_url: rpcUrl,
    }
  }

  return chainData
}

export async function formatTestTransaction(address, chainId) {
  const from = address
  const to = address

  const _nonce = await apiGetAccountNonce(address, chainId)
  const nonce = sanitizeHex(convertStringToHex(_nonce))

  const gasPrices = await apiGetGasPrices()
  const _gasPrice = gasPrices.slow.price
  const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

  const _gasLimit = 500000
  const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

  const _value = 0
  const value = sanitizeHex(convertStringToHex(_value))

  const data = '0x'

  const tx = {
    from,
    to,
    nonce,
    gasPrice,
    gasLimit,
    value,
    data,
  }

  return tx
}
