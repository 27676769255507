import React, { useEffect, useState } from 'react'
import { TransactionModel } from '../../../utils/models/clientModels'
import { getResentTransactions } from '../contractDetailsCRUD'
import { KTSVG } from '../../../../_metronic/helpers'
import moment from 'moment'
import Web3 from 'web3'

export function RecentTransactionsTable(props: { address: string }) {
  const { address } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [recentTransactions, setRecentTransactions] = useState<TransactionModel[]>([])

  useEffect(() => {
    if (address) {
      setLoading(true)
      getResentTransactions(address)
        .then((res) => {
          setLoading(false)
          setError('')
          setRecentTransactions(res)
        })
        .catch((err) => {
          setError(err)
          setLoading(false)
        })
    } else {
      setLoading(true)
    }
  }, [address])

  return (
    <div className="card mb-5 mb-xl-8 mt-8 pt-2 shadow">
      <div className='card-body py-3'>
        <div className='h3 mt-5 mb-8 padding-start-match'>Recent Transactions</div>
        <div className='table-responsive mt-3 mb-5 border border-secondary rounded'>
          <table className='table align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='min-w-200px header-match'>Timestamp</th>
                <th className='min-w-200px header-match'>Transaction Hash</th>
                <th className='min-w-200px header-match'>Method</th>
                <th className='min-w-200px header-match'>From</th>
                <th className='min-w-200px header-match'>To</th>
                <th className='min-w-200px header-match'>Value</th>
                <th className='min-w-200px header-match'>Gas Limit</th>
                <th className='min-w-200px header-match'>Gas Price</th>
                <th className='min-w-200px header-match'>Gas Used</th>
                <th className='min-w-200px header-match'>Transaction Fee</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                !error &&
                recentTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {transaction?.timeStamp
                          ? moment.unix(parseInt(transaction?.timeStamp)).format('LLL')
                          : '-'}
                      </p>
                    </td>
                    <td className='body-match'>
                      <a
                        href={`https://rinkeby.etherscan.io/tx/${transaction?.hash}`}
                        target="_blank"
                        rel="noreferrer"
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {transaction?.hash}
                        <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                      </a>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {transaction?.methodId}
                      </p>
                    </td>
                    <td className='body-match'>
                      <a
                        href={`https://rinkeby.etherscan.io/address/${transaction?.from}`}
                        target="_blank"
                        rel="noreferrer"
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {transaction?.from}
                        <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                      </a>
                    </td>
                    <td className='body-match'>
                      <a
                        href={`https://rinkeby.etherscan.io/address/${transaction?.to}`}
                        target="_blank"
                        rel="noreferrer"
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {
                          transaction?.to ? (
                            <>
                              {transaction?.to}
                              <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                            </>
                          ) : '-'
                        }
                      </a>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {Web3.utils.fromWei(transaction?.value, 'ether')} ETH
                      </p>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {transaction?.gas} Units
                      </p>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder d-block mb-1 fs-6'
                      >
                        {Web3.utils.fromWei(transaction?.gasPrice, 'gwei')} GWEI
                      </p>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder d-block mb-1 fs-6'
                      >
                        {transaction?.gasUsed} Units
                      </p>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder d-block mb-1 fs-6'
                      >
                        {
                          transaction?.gasUsed && (
                            Web3.utils.fromWei(Web3.utils.toBN(transaction?.gasUsed).mul(Web3.utils.toBN(transaction?.gasPrice)), 'ether')
                          )
                        } ETH
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!loading && error && <div className='text-center text-danger my-6'>{error}</div>}
          {loading && <div className='text-center text-dark my-6'>Loading ...</div>}
        </div>
      </div>
    </div>
  )
}
