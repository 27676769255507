import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBwatmy9iIWn1gILnJV0k6USABjpZ5KEaQ',
  authDomain: 'mighty-samo-platform.firebaseapp.com',
  projectId: 'mighty-samo-platform',
  storageBucket: 'mighty-samo-platform.appspot.com',
  messagingSenderId: '832850975552',
  appId: '1:832850975552:web:2274a71badcf4e75e6b8a5',
  measurementId: 'G-BV87SKZJME',
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)

const secondaryAppConfig = {
  apiKey: "AIzaSyDwtqpoH9GO06wjuUwkmLcQxV6Ib2pkPtk",
  authDomain: "mighty-samo.firebaseapp.com",
  projectId: "mighty-samo",
  storageBucket: "mighty-samo.appspot.com",
  messagingSenderId: "973513387157",
  appId: "1:973513387157:web:6c3449d27cb2b9aa58ff9c",
  measurementId: "G-RYX62BKEJ0"
}

const secondaryApp = initializeApp(secondaryAppConfig, "secondary")

export const secondaryDb = getFirestore(secondaryApp)
