import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'

function ChangeValueModel({ modelTitle, count, changeStatus, modelId }) {
  const [value, setValue] = useState<any>(count || '')

  useEffect(() => {
    setValue(count)
  }, [count])

  return (
    <div className='modal fade' id={modelId} aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-500px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Change {modelTitle}</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='m-auto w-75 mt-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>{modelTitle}</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='id'
              placeholder=''
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              data-bs-dismiss='modal'
              className='btn btn-primary w-100 mt-7'
              onClick={() => changeStatus(value)}
            >
              Submit
            </button>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'></div>
        </div>
      </div>
    </div>
  )
}

export default ChangeValueModel
