import React, { useContext, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { tokensOfGroup } from '../../../../helpers/web3'
import { toast } from 'react-toastify'

function TokensOfGroup(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [owner, setOwner] = useState<number>(-1)
  const [status, setStatus] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = () => {
    if (owner > 0) {
        setLoading(true)
        tokensOfGroup(owner, config.chainId, config.web3, contractDetails.address, contractDetails.variant)
        .then((res: any) => {
            console.log('RES', res)
            setStatus(res.join(', '))
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
            toast.error(err.message || 'Error getting group tokens')
        })
    }
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 fw-bolder mb-6'>
                Group Tokens
            </p>

            <div className='d-flex flex-center flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'>Token IDs</div>
                <div className='fw-bold text-gray-400'>
                  {status ? status : '-'}
                </div>
              </div>
            </div>

            <div className='mb-5 w-75'>
              <input
                value={owner > 0 ? owner : ''}
                onChange={(e) => setOwner(parseInt(e.target.value))}
                placeholder='Owner'
                className='form-control form-control-lg form-control-solid'
                type='number'
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={getStatus}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Get Tokens'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TokensOfGroup
