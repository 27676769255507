import React, { useContext, useEffect, useState, useCallback } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { isRevealed, flipIsRevealed } from '../../../../helpers/web3'
import { ContractModel } from '../../../utils/models/clientModels'
import { toast } from 'react-toastify'

function Revealed(props: { contractDetails: ContractModel }) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [status, setStatus] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = useCallback(() => {
    isRevealed(config.chainId, config.web3, contractDetails.address, contractDetails.variant)
      .then((res) => {
        setStatus(res)
      })
      .catch((err) => {
        console.log(err)
        toast.error('Error getting sales status')
      })
  }, [config.chainId, config.web3, contractDetails.address, contractDetails.variant])

  const changeStatus = () => {
    setLoading(true)

    flipIsRevealed(contractDetails.owner, config.chainId, config.web3, contractDetails.address, contractDetails.variant)
    .then((res) => {
        getStatus()
        setLoading(false)
        toast.success('Revealed status changed successfully')
    })
    .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error('Unknown error occurred')
    })
  }

  useEffect(() => {
    if (contractDetails?.address) {
      getStatus()
    }
  }, [contractDetails.address, getStatus])

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 fw-bolder mb-6 text-start'>
              Random Reveal
            </p>

            <div className='d-flex flex-center flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'>Current state</div>
                <div className='fw-bold text-gray-400'>
                  {status !== undefined ? (status ? 'Revealed' : 'Not Revealed') : '-'}
                </div>
              </div>
            </div>

            <button
              onClick={changeStatus}
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Change State'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Revealed
