import React from 'react'
import {
  Link,
  // Outlet
} from 'react-router-dom'
// import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

function AboutUs(props) {
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <Link to='/' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/assets/logo-1.png')}
            className='h-70px'
          />
        </Link>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>About us</h1>
            <div>
              <p className='mt-14'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
              <p className='mb-0'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <Link to='/auth/login' className='text-muted text-hover-primary px-2'>
            Login
          </Link>
          <Link to='/contact-us' className='text-muted text-hover-primary px-2'>
            Contact
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
