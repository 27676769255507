import React, { useContext, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import {
  setAttribute
} from '../../../../helpers/web3'
import { toast } from 'react-toastify'

function SetAttribute(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [attributeType, setAttributeType] = useState<string>('')
  const [attributeName, setAttributeName] = useState<string>('')
  const [count, setCount] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const changeStatus = () => {
    setLoading(true)
    setAttribute(
      attributeType,
      attributeName,
      count,
      contractDetails.owner,
      config.chainId,
      config.web3,
      contractDetails.address,
      contractDetails.variant
    )
      .then((res) => {
        setLoading(false)
        toast.success('Attribute set successfully!')
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error('Error adding attribute.')
      })
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 fw-bolder mb-6'>
              Set Random Attribute
            </p>

            <div className='mb-5 w-75'>
              <input
                value={attributeType}
                onChange={(e) => setAttributeType(e.target.value)}
                placeholder='Attribute Type'
                className='form-control form-control-lg form-control-solid my-5'
              />
              <input
                value={attributeName}
                onChange={(e) => setAttributeName(e.target.value)}
                placeholder='Attribute Name'
                className='form-control form-control-lg form-control-solid my-5'
              />
              <input
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                placeholder='Count'
                className='form-control form-control-lg form-control-solid my-5'
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={changeStatus}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Set Attribute'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SetAttribute
