import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore'
import { db } from '../../../setup/firebase/firebaseConfig'
import { ClientModel } from '../../utils/models/clientModels'
import { mapDocToClient } from '../../utils/Mappers/clientMappers'

export async function getMyClientsSaga(email: string) {
  return new Promise<ClientModel[]>(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'memberships', email)
      const docSnap = await getDoc(docRef)
      let clientIds: string[] = []
      if (docSnap.exists() && docSnap.data().clients) {
        clientIds = docSnap.data().clients
      }
      let clients: ClientModel[] = []
      await Promise.all(
        clientIds.map(async (clientId) => {
          const clientsRef = doc(db, 'clients', clientId)
          const docSnap = await getDoc(clientsRef)
          clients.push(mapDocToClient(docSnap))
        })
      )
      resolve(clients)
    } catch (e) {
      reject('Unknown Error')
    }
  })
}

export async function addClient(email: string, clientDetails: ClientModel) {
  const { name, totalContracts, id } = clientDetails
  return new Promise<string>(async (resolve, reject) => {
    try {
      const clientsRef = doc(db, 'clients', id)
      const docSnap = await getDoc(clientsRef)
      if (docSnap.exists()) {
        reject('Client ID already exist')
      }

      await setDoc(doc(db, 'clients', id), { name, totalContracts, contracts: [] })
      const membershipsRef = doc(db, 'memberships', email)
      await updateDoc(membershipsRef, {
        clients: arrayUnion(id),
      })
      resolve('')
    } catch (e) {
      console.log(e)
      reject('Error occurred while adding client')
    }
  })
}

export async function shareClient(membershipID: string, clientID: string) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const membershipsRef = doc(db, 'memberships', membershipID)
      await updateDoc(membershipsRef, {
        clients: arrayUnion(clientID),
      })
      resolve('')
    } catch (e) {
      console.log(e)
      reject(e)
    }
  })
}
