import React, {
  // useContext,
  useEffect,
  useState
} from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { getMyClientsSaga } from './clientsCRUD'
import { ClientModel } from '../../utils/models/clientModels'
import { Link } from 'react-router-dom'
import { AddClientModel } from './components/AddClientModel'
import { ShareClientModel } from './components/ShareClientModel'
import { KTSVG } from '../../../_metronic/helpers'

function ClientPage(props: {
  clients: ClientModel[]
  loading: boolean
  error: string
}): JSX.Element {
  const { clients, loading, error } = props
  return (
    <div className="card mb-5 mb-xl-8 shadow">
      <div className='card-header border-0 pt-5 mt-3'>
        <h3 className='card-title align-items-start flex-column padding-start-match'>
          <span className='card-label fw-bolder fs-3 mb-1'>My Clients</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Total {clients.length} clients</span>
        </h3>
        <div className='d-flex align-items-center justify-content-end padding-end-match'>
          <button
            type='button'
            className='btn btn-sm btn-primary mx-3 d-inline-flex align-items-center'
            data-bs-toggle='modal'
            data-bs-target='#modal_share_client'
            id='kt_toolbar_primary_button'
          >
            <KTSVG path='/media/icons/duotune/general/gen041.svg' className='me-3' />
            Share A Client
          </button>
          <button
            type='button'
            className='btn btn-sm btn-primary d-inline-flex align-items-center'
            data-bs-toggle='modal'
            data-bs-target='#modal_create_client'
            id='kt_toolbar_primary_button'
          >
            <KTSVG path='/media/icons/duotune/general/gen041.svg' className='me-3' />
            Create Client
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive border border-secondary rounded mt-3 mb-5'>
          <table className='table table-bordered align-middle gs-0 gy-4 m-0'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='min-w-100px header-match'>Name</th>
                <th className='min-w-200px header-match'>Total Contracts</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                !error &&
                clients.map((client, index) => (
                  <tr key={index}>
                    <td className='body-match'>
                      <Link
                        to={`/contracts/${client?.id}`}
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {
                          client?.name ? (
                            <>
                              {client?.name}
                              <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='ms-3 dark-icon' />
                            </>
                          ) : '-'
                        }
                      </Link>
                    </td>
                    <td className='body-match'>
                      <p
                        className='text-dark fw-bolder d-block mb-1 fs-6'
                      >
                        {client?.totalContracts || '0'}
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!loading && error && <div className='text-center text-danger my-6'>{error}</div>}
          {loading && <div className='text-center text-dark my-6'>Loading ...</div>}
        </div>
      </div>
    </div>
  )
}

function ClientsWrapper() {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [clients, setClients] = useState<ClientModel[]>([])

  const getMyClient = () => {
    console.log('Getting Clients')

    setLoading(true)
    getMyClientsSaga(user.email)
      .then((res: ClientModel[]) => {
        setLoading(false)
        setClients(res)
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
        console.log(err)
      })
  }

  useEffect(() => {
    getMyClient()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageTitle breadcrumbs={[]}>Clients</PageTitle>
      <ClientPage clients={clients} loading={loading} error={error} />
      <AddClientModel getMyClient={getMyClient} />
      <ShareClientModel getMyClient={getMyClient} clients={clients} />
    </>
  )
}

export default ClientsWrapper
