// import { ContractModel, TransactionModel } from '../../utils/models/clientModels'
// import { doc, getDoc } from 'firebase/firestore'
// import { db } from '../../../setup/firebase/firebaseConfig'
// import { mapDocToContract } from '../../utils/Mappers/clientMappers'
import axios from 'axios'

export async function submitContactForm(formData) {
  return new Promise<boolean>(async (resolve, reject) => {
    await axios
      .post('https://hooks.zapier.com/hooks/catch/6986406/bay5ftu/', formData)
      .then(({ data }) => {
        resolve(true)
      })
      .catch((err) => {
        console.log(err)
        reject('Unknown Error')
      })
  })
}
