import React, { useContext, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useParams, Link, useSearchParams } from 'react-router-dom'
import { getContract } from './contractDetailsCRUD'
import { ContractModel } from '../../utils/models/clientModels'
import { RecentTransactionsTable } from './components/RecentTransactionsTable'
import BasicContractDetails from './components/BasicContractDetailsContractDetails'
import { totalTokens } from '../../../helpers/web3'
import { WalletContext } from '../../../helpers/WalletProvider'
import Sales from './components/Sales'
import WhitelistSale from './components/WhitelistSale'
import StandardSupply from './components/StandardSupply'
import CheckWhitelist from './components/CheckWhitelist'
import GenerateWhitelistSignature from './components/GenerateWhitelistSignature'
import RecentTokenTransactionsTable from './components/RecentTokenTransactionsTable'
import StandardPrice from './components/StandardPrice'
import WhitelistSupply from './components/WhitelistSupply'
import MultiMintComponent from './components/MultiMintComponent'
import MintStandardReserved from './components/MintStandardReserved'
import BaseUri from './components/BaseURI'
import TokenURI from './components/TokenURI'
import SetWhitelistSignature from './components/SetWhitelistSignature'
import PaymentRelease from './components/PaymentRelease'
import MaxReservedSupply from './components/MaxReservedSupply'
import MaxStandardSupply from './components/MaxStandardSupply'
import AllowedStandardMintCount from './components/AllowedStandardMintCount'
import MaxSupply from './components/MaxSupply'
import GenerateFileSignature from './components/GenerateFileSignature'
import VerifyFileSignature from './components/VerifyFileSignature'
import SetAdmin from './components/SetAdmin'
import ChangeGroupMaxSupply from './components/ChangeGroupMaxSupply'
import supportedChains from '../../../helpers/chains'
import GroupsOfOwner from './components/GroupsOfOwner'
import TokensOfGroup from './components/TokensOfGroup'
import GenerateFileSignatureTwo from './components/GenerateFileSignatureTwo'
import RevealedBaseUri from './components/RevealedBaseURI'
import Revealed from './components/Revealed'
import SetAttribute from './components/SetAttribute'
import AttributeTotal from './components/AttributeTotal'
import AttributeForToken from './components/AttributeForToken'
import { KTSVG } from '../../../_metronic/helpers'

function ContractDetails() {
  let { contractId } = useParams()
  const [contractDetails, setContractDetails] = useState<ContractModel>({
    id: '',
    name: '',
    address: '',
    owner: '',
    variant: '',
    networkId: null,
    containerUrl: '',
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [maxSupply, setMaxSupply] = useState<number>(0)
  const [maxSupplyLoading, setMaxSupplyLoading] = useState<boolean>(true)
  const [, setError] = useState<string>('')
  const [chainName, setChainName] = useState<string>('')
  const { config, onConnect } = useContext(WalletContext)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true)
    getContract(contractId || '')
      .then((res) => {
        setError('')
        setContractDetails(res)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        setLoading(false)
      })
  }, [contractId])

  useEffect(() => {
    if (config.address && contractDetails.address) {
      setMaxSupplyLoading(true)
      totalTokens(config.chainId, config.web3, contractDetails.address, contractDetails.variant)
        .then((res) => {
          console.log('Total tokens', res)
          setMaxSupply(res)
          setMaxSupplyLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setMaxSupplyLoading(false)
        })
    }
  }, [config.address, config.web3, config.chainId, contractDetails.address, contractDetails.variant])

  useEffect(() => {
    const index = supportedChains.map((c) => c.chain_id).indexOf(config.chainId || -1)
    if (index > -1) {
      setChainName(supportedChains[index].name)
    }
  }, [config.chainId, setChainName])

  return (
    <>
      {
        searchParams.get('clientId') && (
          <Link
            to={`/contracts/${searchParams.get('clientId')}`}
            className='btn btn-sm btn-primary fw-bolder mt-0 mb-8 me-3 shadow d-inline-flex align-items-center'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='me-3 dark-icon' />      
            Back to contracts
          </Link>
        )
      }
      <PageTitle breadcrumbs={[]}>Contract Details</PageTitle>
      {config.address !== '' ? (
        <>
          {config.chainId === contractDetails.networkId ? (
            <>
              {['Option B', 'Option C', 'Option D', 'Option E', 'Option F'].includes(contractDetails.variant) && (
                <BasicContractDetails
                  contractDetails={contractDetails}
                  loading={loading}
                  maxSupply={maxSupply}
                  maxSupplyLoading={maxSupplyLoading}
                  chainName={chainName}
                  networkId={contractDetails.networkId}
                />
              )}
              <div className='row g-6 g-xl-9'>
                {['Option B', 'Option C', 'Option E', 'Option F'].includes(contractDetails.variant) && (
                  <Sales contractDetails={contractDetails} altMethod={contractDetails.variant === 'Option E' ? true : false} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <WhitelistSale contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <Revealed contractDetails={contractDetails} />
                )}

                {['Option E', 'Option F'].includes(contractDetails.variant) && (
                  <MaxSupply contractDetails={contractDetails} />
                )}

                {['Option E'].includes(contractDetails.variant) && (
                  <SetAdmin contractDetails={contractDetails} />
                )}

                {['Option E'].includes(contractDetails.variant) && (
                  <ChangeGroupMaxSupply />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <StandardSupply contractDetails={contractDetails} />
                )}

                {['Option C'].includes(contractDetails.variant) && (
                  <MaxStandardSupply contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <WhitelistSupply contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <StandardPrice contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <MultiMintComponent contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <CheckWhitelist contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <SetWhitelistSignature contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C', 'Option E', 'Option F'].includes(contractDetails.variant) && (
                  <PaymentRelease contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <GenerateWhitelistSignature contractDetails={contractDetails} />
                )}

                {['Option E'].includes(contractDetails.variant) && (
                  <GenerateFileSignature contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <GenerateFileSignatureTwo contractDetails={contractDetails} />
                )}

                {['Option E'].includes(contractDetails.variant) && (
                  <VerifyFileSignature contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <MintStandardReserved contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C', 'Option D', 'Option E', 'Option F'].includes(contractDetails.variant) && (
                  <BaseUri contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <RevealedBaseUri contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C'].includes(contractDetails.variant) && (
                  <AllowedStandardMintCount contractDetails={contractDetails} />
                )}

                {['Option B', 'Option C', 'Option E', 'Option D', 'Option F'].includes(contractDetails.variant) && (
                  <TokenURI contractDetails={contractDetails} />
                )}

                {['Option C'].includes(contractDetails.variant) && (
                  <MaxReservedSupply contractDetails={contractDetails} />
                )}

                {['Option D'].includes(contractDetails.variant) && (
                  <GroupsOfOwner contractDetails={contractDetails} />
                )}

                {['Option D'].includes(contractDetails.variant) && (
                  <TokensOfGroup contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <SetAttribute contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <AttributeTotal contractDetails={contractDetails} />
                )}

                {['Option F'].includes(contractDetails.variant) && (
                  <AttributeForToken contractDetails={contractDetails} />
                )}
              </div>
              <RecentTransactionsTable address={contractDetails.address} />
              <RecentTokenTransactionsTable address={contractDetails.address} />
            </>
          ) : (
            <div className='bg-white my-7 p-10 h5 text-center text-danger'>
              <p>Please switch to the correct network and reconnect wallet to continue</p>
              <p>Wallet chain ID - {config.chainId}</p>
              <p className='mb-0'>Contract's network ID - {contractDetails.networkId}</p>
            </div>
          )}
        </>
      ) : (
        <div>
          <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
            <button
              className='btn btn-custom btn-primary w-100'
              onClick={onConnect}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Check out the complete documentation with over 100 components'
            >
              <span className='btn-label'>Connect Wallet</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ContractDetails
