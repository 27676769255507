import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../setup/firebase/firebaseConfig'
import { ContractModel } from '../../utils/models/clientModels'
import { mapDocToContract } from '../../utils/Mappers/clientMappers'

export async function getContractsList(email: string, clientId: string) {
  return new Promise<ContractModel[]>(async (resolve, reject) => {
    try {
      const membershipsRef = doc(db, 'memberships', email)
      const membershipsRefSnap = await getDoc(membershipsRef)
      let clientIds: string[] = []
      if (membershipsRefSnap.exists() && membershipsRefSnap.data().clients) {
        clientIds = membershipsRefSnap.data().clients
      }

      let contracts: ContractModel[] = []

      if (clientIds.includes(clientId)) {
        const clientsRef = doc(db, 'clients', clientId)
        const clientsSnap = await getDoc(clientsRef)
        let contractIds: string[] = []
        if (clientsSnap.exists() && clientsSnap.data().contracts) {
          contractIds = clientsSnap.data().contracts
        }
        await Promise.all(
          contractIds.map(async (contractId) => {
            const contractsRef = doc(db, 'contracts', contractId)
            const contractsSnap = await getDoc(contractsRef)
            contracts.push(mapDocToContract(contractsSnap))
          })
        )
        resolve(contracts)
      } else {
        reject("This client doesn't belongs to you")
      }
    } catch (e) {
      console.log(e)
      reject('Unknown Error')
    }
  })
}
