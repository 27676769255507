import optionBAbi from './abis/option_b_abi.json'
import optionCAbi from './abis/option_c_abi.json'
import optionDAbi from './abis/option_d_abi.json'
import optionEAbi from './abis/option_e_abi.json'
import optionFAbi from './abis/option_f_abi.json'

export const CONTRACT = {
  4: {
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: optionBAbi,
  },
  1: {
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: optionBAbi,
  },
}

export const ABIS = {
  optionB: optionBAbi,
  optionC: optionCAbi,
  optionD: optionDAbi,
  optionE: optionEAbi,
  optionF: optionFAbi,
}

export const getAbiKey = (variant) => {
  const _split = variant.split(' ')
  _split[0] = _split[0].toLowerCase()
  _split[1] = _split[1].toUpperCase()
  const _joined = _split.join('')

  return _joined
}
