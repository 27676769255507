import firebase from 'firebase/compat'
import { ClientModel, ContractModel } from '../models/clientModels'

export const mapDocToClient = (docSnap: firebase.firestore.DocumentData): ClientModel => {
  const doc = docSnap.data()
  return {
    id: docSnap?.id,
    name: doc?.name || '',
    totalContracts: doc?.totalContracts || '',
  }
}

export const mapDocToContract = (docSnap: firebase.firestore.DocumentData): ContractModel => {
  const doc = docSnap.data()
  return {
    id: docSnap?.id,
    name: doc?.name || '',
    address: doc?.address || '',
    owner: doc?.owner || '',
    variant: doc?.variant || '',
    networkId: doc?.networkId || '',
    containerUrl: doc?.containerUrl || '',
  }
}
