import React, { useContext, useEffect, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { maxStandardWhitelist, setMaxStandardWhitelist } from '../../../../helpers/web3'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
import ChangeValueModel from './common/ChangeValueModel'

function WhitelistSupply(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [status, setStatus] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = () => {
    maxStandardWhitelist(config.chainId, config.web3, contractDetails.address)
      .then((res) => {
        setStatus(res)
      })
      .catch((err) => {
        console.log(err)
        toast.error('Error getting sales status')
      })
  }

  const changeStatus = (count) => {
    setLoading(true)
    setMaxStandardWhitelist(
      count.toString(),
      contractDetails.owner,
      config.chainId,
      config.web3,
      contractDetails.address
    )
      .then((res) => {
        getStatus()
        setLoading(false)
        toast.success('Sales status changed successfully')
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error('Unknown error occurred')
      })
  }

  useEffect(() => {
    if (contractDetails?.address) {
      getStatus()
    }
  }, [contractDetails.address])

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-6'>
              Whitelist Supply
            </a>

            <div className='d-flex flex-center flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'>Current state</div>
                <div className='fw-bold text-gray-400'>
                  {status !== undefined ? (status ? status : '-') : '-'}
                </div>
              </div>
            </div>

            <button
              data-bs-toggle='modal'
              data-bs-target='#modal_change_whitelist_supply_value'
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border' role='loading'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Change State'
              )}
            </button>
          </div>
        </div>
        <ChangeValueModel
          modelId='modal_change_whitelist_supply_value'
          modelTitle='Whitelist Supply'
          count={status}
          changeStatus={changeStatus}
        />
      </div>
    </>
  )
}

export default WhitelistSupply
