/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { addClient } from '../clientsCRUD'
import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'

interface ICreateAccount {
  name: string
  id: string
  totalContracts: number
}

const inits: ICreateAccount = {
  name: '',
  id: '',
  totalContracts: 0,
}

const createAppSchema = [
  Yup.object({
    name: Yup.string().required().label('name'),
    id: Yup.string().required().label('id'),
  }),
  Yup.object({}),
]

export function AddClientModel({ getMyClient }) {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [loading, setLoading] = useState<boolean>(false)

  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setLoading(true)
      try {
        await addClient(user.email, values)
        document.getElementById('modal_create_client')?.click()
        getMyClient()
        toast.success('Client added successfully')
      } catch (e) {
        if (typeof e === 'string') {
          toast.error(e)
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='modal fade' id='modal_create_client' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Add Client</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Details</h3>

                      <div className='stepper-desc'>Name your App</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Submit Form</h3>

                      <div className='stepper-desc'>Define your app framework</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {({ values }) => (
                    <Form className='form' noValidate id='kt_modal_create_app_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Client Id</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your unique app name'
                              ></i>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='id'
                              placeholder=''
                            />
                            <div className='text-danger'>
                              {/*@ts-ignore*/}
                              <ErrorMessage name='id' />
                            </div>
                          </div>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Client Name</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your unique app name'
                              ></i>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='name'
                              placeholder=''
                            />
                            <div className='text-danger'>
                              {/*@ts-ignore*/}
                              <ErrorMessage name='name' />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div>
                          <div>Client Id :-{values.id}</div>
                          <div>Client Name :-{values.name}</div>
                        </div>
                      </div>

                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button
                            type='submit'
                            className={`${loading && 'disabled'} btn btn-lg btn-primary me-3`}
                            style={{ minWidth: '140px' }}
                          >
                            {!loading ? (
                              <span className='indicator-label'>
                                {stepper.current?.currentStepIndex !==
                                  stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-3 ms-2 me-0'
                                />
                              </span>
                            ) : (
                              <div className='spinner-grow-sm spinner-border' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
