import React, { useContext, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { release } from '../../../../helpers/web3'
import { toast } from 'react-toastify'

function PaymentRelease(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [owner, setOwner] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = () => {
    setLoading(true)
    release(owner, config.address, config.chainId, config.web3, contractDetails.address)
      .then((res) => {
        toast.error('Release payment success')
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error(err.message || 'Error releasing payment')
      })
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-6'>
              Payment Release
            </p>

            <div className='mb-5 w-75'>
              <input
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                placeholder='Owner'
                className='form-control form-control-lg form-control-solid '
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={getStatus}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Release payment'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentRelease
