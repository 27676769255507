import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { ethers } from 'ethers'

import { CONTRACT, ABIS, getAbiKey } from './contracts'
import { apiGetGasPrices, apiGetAccountNonce } from './api'
import { convertAmountToRawNumber, convertStringToHex } from './bignumber'
import { sanitizeHex } from './utilities'

export function getContract(chainId, web3, contractAddress = null, contractVariant = null) {
  let _contractAbi = CONTRACT[chainId].abi
  let _contractAddress = CONTRACT[chainId].address

  if (contractAddress !== null) _contractAddress = contractAddress
  if (contractVariant !== null) _contractAbi = ABIS[getAbiKey(contractVariant)]

  const contract = new web3.eth.Contract(_contractAbi, _contractAddress)
  return contract
}

export function baseTokenURI(chainId, web3, contractAddress, contractVariant) {
  return new Promise<string>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const result = await contract.methods
        .baseTokenURI()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function tokenURI(tokenId, chainId, web3, contractAddress) {
  return new Promise<string>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .tokenURI(`${tokenId}`)
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setBaseURI(uri, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setBaseURI(`${uri}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setRevealedBaseURI(uri, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setRevealedBaseURI(`${uri}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setAttribute(attributeType, attributeName, count, address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 150000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setAttribute(attributeType, attributeName, `${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function attributeForToken(tokenId, attributeType, chainId, web3, contractAddress, contractVariant) {
  return new Promise<string>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const result = await contract.methods
        .attributeForToken(`${tokenId}`, attributeType)
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function attributesTotalsForType(attributeType, attributeName, chainId, web3, contractAddress, contractVariant) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const result = await contract.methods
        .attributesTotalsForType(attributeType, attributeName)
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}


export function maxSupply(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .MAX_SUPPLY()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function maxStandard(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .MAX_STANDARD_SUPPLY()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function release(owner, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 100000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.release(owner).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setAdmin(owner, address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
      const contract = getContract(chainId, web3, contractAddress, contractVariant);

      try {
          const _nonce = await apiGetAccountNonce(address, chainId);
          const nonce = sanitizeHex(convertStringToHex(_nonce));

          const gasPrices = await apiGetGasPrices();
          const _gasPrice = gasPrices.slow.price;
          const gasPrice = sanitizeHex(
              convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
          );

          const _gasLimit = 80000;
          const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
          
          const _value = 0;
          const value = sanitizeHex(convertStringToHex(_value));

          const data = "0x";
  
          const result = await contract.methods
          .setAdmin(owner, true)
          .send({
              from: address,
              value,
              gasPrice,
              gasLimit,
              nonce,
              data
          });
          resolve(result);
      }
      catch (err) {
          reject(err);
      }
  });
}

export function setMaxSupply(count, address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setMaxSupply(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setMaxStandardSupply(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setMaxStandardSupply(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setMaxStandardReservedSupply(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setMaxStandardReservedSupply(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function maxPremium(chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .MAX_PREMIUM_SUPPLY()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function maxStandardPerWallet(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    await contract.methods
      .MAX_STANDARD_PER_WALLET()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function setMaxStandardPerWallet(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setStandardLimitPerWallet(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function maxPremiumPerWallet(chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    await contract.methods
      .MAX_PREMIUM_PER_WALLET()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function maxStandardWhitelist(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    await contract.methods
      .MAX_STANDARD_WHITELIST_SUPPLY()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function maxStandardReserved(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    await contract.methods
      .MAX_STANDARD_RESERVED_SUPPLY()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function setMaxStandardWhitelist(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setMaxStandardWhitelistSupply(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function balanceOf(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    await contract.methods
      .balanceOf(address)
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function groupsOfOwner(address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    await contract.methods
      .groupsOfOwner(address)
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function tokensOfGroup(groupId, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    await contract.methods
      .tokensOfGroup(`${groupId}`)
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export function allowedPremiumMintCount(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const data = await contract.methods
        .allowedPremiumMintCount(address)
        .call({ from: '0x0000000000000000000000000000000000000000' })

      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function allowedStandardMintCount(address, chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)
    try {
      await contract.methods
        .allowedStandardMintCount(address)
        .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
          if (err) {
            reject(err)
          }

          resolve(data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export function mintPremium(address, chainId, web3, count, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      let _value: any = new BigNumber('69000000000000000')
      _value = _value.times(count).toString()
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintPremium(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function mintStandard(address, chainId, web3, count, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintStandard(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function standardPrice(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .STANDARD_PRICE()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setStandardPrice(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods
        .setStandardPrice(`${Web3.utils.toWei(count, 'ether')}`)
        .send({
          from: address,
          value,
          gasPrice,
          gasLimit,
          nonce,
          data,
        })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function maxMultimint(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .MAX_MULTIMINT()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setMultiMint(count, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setMultiMint(`${count}`).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function saleIsActive(chainId, web3, contractAddress, contractVariant) {
  return new Promise<boolean>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const result = await contract.methods
        .saleIsActive()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function isRevealed(chainId, web3, contractAddress, contractVariant) {
  return new Promise<boolean>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const result = await contract.methods
        .isRevealed()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function whitelistSaleIsActive(chainId, web3, contractAddress) {
  return new Promise<boolean>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .whitelistSaleIsActive()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function flipSaleIsActive(address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.flipSaleIsActive().send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function flipIsRevealed(address, chainId, web3, contractAddress, contractVariant) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.flipIsRevealed().send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function flipSalesState(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.flipSaleState().send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function flipWhitelistSaleState(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.flipWhitelistSaleState().send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function setWhitelistSigningAddress(owner, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 80000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.setWhitelistSigningAddress(owner).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function generateWhitelistSignature(owner, chainId, ethers, contractAddress) {
  return new Promise<string>(async (resolve, reject) => {
    const domain = {
      name: 'WhitelistToken',
      version: '1',
      chainId,
      verifyingContract: contractAddress,
    }

    const types = {
      Minter: [{ name: 'wallet', type: 'address' }],
    }

    try {
      const signer = ethers.getSigner()
      const sig = await signer._signTypedData(domain, types, {
        wallet: owner.toLowerCase(),
      })
      resolve(sig)
    } catch (err) {
      reject(err)
    }
  })
}

export function generateFileSignature(wallet, chainId, _ethers, contractAddress) {
  return new Promise<string>(async (resolve, reject) => {
    const domain = {
      name: 'FileSignatureToken',
      version: '1',
      chainId,
      verifyingContract: contractAddress,
    }

    const types = {
      Owner: [{ name: 'wallet', type: 'string' }],
    }

    try {
      const signer = _ethers.getSigner()
      const sig = await signer._signTypedData(domain, types, { wallet })

      const recoveredAddress = ethers.utils.verifyTypedData(domain, types, { wallet }, sig);
      console.log('REC', recoveredAddress);

      resolve(sig)
    } catch (err) {
      reject(err)
    }
  })
}

export function signFileSignatureToken(signingName, signingMetadata, signingDataId, chainId, web3, _ethers, contractAddress) {
  return new Promise<string>(async (resolve, reject) => {
    const domain = {
      name: 'FileSignatureToken',
      version: '1',
      chainId,
      verifyingContract: contractAddress
    };

    const types = {
      Owner: [
        { name: 'name', type: 'string' },
        { name: 'metadataHash', type: 'string' },
        { name: 'dataId', type: 'string' }
      ]
    };

    try {
      const signer = _ethers.getSigner();
      const metadataHash = web3.utils.soliditySha3(JSON.stringify(signingMetadata));
      const sig = await signer._signTypedData(domain, types, { name: signingName, metadataHash, dataId: signingDataId });
      resolve(sig);
    }
    catch (err) {
      reject(err);
    }
  });
}

export function mintStandardReservedToAddress(
  count,
  owner,
  address,
  chainId,
  web3,
  contractAddress
) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintStandardReservedToAddress(`${count}`, owner).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function mintPremiumReservedToAddress(
  count,
  owner,
  address,
  chainId,
  web3,
  contractAddress
) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintPremiumReservedToAddress(`${count}`, owner).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function mintStandardWhitelist(count, signature, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      const _value = 0
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintStandardWhitelist(`${count}`, signature).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function mintPremiumWhitelist(count, signature, address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const _nonce = await apiGetAccountNonce(address, chainId)
      const nonce = sanitizeHex(convertStringToHex(_nonce))

      const gasPrices = await apiGetGasPrices()
      const _gasPrice = gasPrices.slow.price
      const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

      const _gasLimit = 500000
      const gasLimit = sanitizeHex(convertStringToHex(_gasLimit))

      let _value: any = new BigNumber('69000000000000000')
      _value = _value.times(count).toString()
      const value = sanitizeHex(convertStringToHex(_value))

      const data = '0x'

      const result = await contract.methods.mintPremiumWhitelist(`${count}`, signature).send({
        from: address,
        value,
        gasPrice,
        gasLimit,
        nonce,
        data,
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function standardReservedMintCount(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .standardReservedMintCount(address)
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function premiumReservedMintCount(address, chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .premiumReservedMintCount(address)
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function checkWhitelist(signature, address, chainId, web3, contractAddress) {
  return new Promise<boolean>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods.checkWhitelist(signature).call({ from: address })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function owner(chainId, web3, contractAddress) {
  return new Promise(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)

    try {
      const result = await contract.methods
        .owner()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

// export async function getContractData() {
//   // @ts-ignore
//   var Web3 = require('web3')
//   /* var provider = 'https://api.etherscan.io/api?apikey=77XATASK5A4HYIPTV9M2BSJD832DZVAY85';
//    var web3Provider = new Web3.providers.HttpProvider(provider);
//    var web3 = new Web3(web3Provider);
//    web3.eth.getBlockNumber().then((result) => {
//        console.log("Latest Ethereum Block is ",result);
//    });*/
//   let web3Provider = Web3.providers.Web3Provider()
//   /*    $.getJSON(, function (data) {
//           var contractABI = "";
//           contractABI = JSON.parse(data.result);
//           if (contractABI != ''){
//               var MyContract = web3.eth.contract(contractABI);
//               var myContractInstance = MyContract.at("0xfb6916095ca1df60bb79ce92ce3ea74c37c5d359");
//               var result = myContractInstance.memberId("0xfe8ad7dd2f564a877cc23feea6c0a9cc2e783715");
//               console.log("result1 : " + result);
//               var result = myContractInstance.members(1);
//               console.log("result2 : " + result);
//           } else {
//               console.log("Error" );
//           }
//       });*/
//   /* axios.get('https://api.etherscan.io/api?apikey=77XATASK5A4HYIPTV9M2BSJD832DZVAY85&module=stats&action=tokensupply&contractaddress=0xc948A696dFC2fDD67C9a24A3B89CB59045A9ea8F').then(({data})=>{
//        console.log(data)
//    })*/
// }

export function totalSupply(chainId, web3, contractAddress) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress)
    try {
      const result = await contract.methods
        .MAX_SUPPLY()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function totalTokens(chainId, web3, contractAddress, contractVariant) {
  return new Promise<number>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)
    try {
      const result = await contract.methods
        .totalTokens()
        .call({ from: '0x0000000000000000000000000000000000000000' })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export function verifyTokenFileSigner(address, message, signature, chainId, web3, contractAddress, contractVariant) {
  return new Promise<boolean>(async (resolve, reject) => {
    const contract = getContract(chainId, web3, contractAddress, contractVariant)
    try {
      const result = await contract.methods
        .verifyTokenFileSigner(message, signature)
        .call({ from: address })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}
