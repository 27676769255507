import React from 'react'
import { StatisticsWidget2 } from '../../../../_metronic/partials/widgets'
import { ContractModel } from '../../../utils/models/clientModels'

function BasicContractDetails(props: {
  contractDetails: ContractModel
  loading: boolean
  maxSupply: number
  maxSupplyLoading: boolean
  networkId: number
  chainName: string
}) {
  const { contractDetails, loading, maxSupply, maxSupplyLoading, chainName, networkId } = props
  return (
    <div className='row g-5 g-xl-8 mt-0'>
      <div className='col-xl-4 mt-0'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8 shadow'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title='Contract Name'
          description={!loading ? contractDetails?.name || 'Unknown' : 'Loading ...'}
        />
      </div>
      <div className='col-xl-4 mt-0'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8 shadow'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title='Contract Owner'
          description={!loading ? contractDetails?.owner || 'Unknown' : 'Loading ...'}
        />
      </div>
      <div className='col-xl-4 mt-0'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8 shadow'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title='Contract Address'
          description={!loading ? contractDetails?.address || 'Unknown' : 'Loading ...'}
        />
      </div>
      <div className='col-xl-4 mt-0'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8 shadow'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title='Total Tokens'
          description={!maxSupplyLoading ? maxSupply.toString() || 'Unknown' : 'Loading ...'}
        />
      </div>
      <div className='col-xl-4 mt-0'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8 shadow'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title='Network ID'
          description={!loading ? `${networkId} (${chainName})` || 'Unknown' : 'Loading ...'}
        />
      </div>
    </div>
  )
}

export default BasicContractDetails
