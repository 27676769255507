import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { ContractModel } from '../../utils/models/clientModels'
import { getContractsList } from './contractsCRUD'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'

function ContractsPage(props: {
  contracts: ContractModel[]
  loading: boolean
  error: string,
  clientId: string | undefined
}): JSX.Element {
  const { contracts, loading, error, clientId } = props
  return (
    <div>
      <Link
        to="/clients"
        className='btn btn-sm btn-primary fw-bolder mt-0 mb-8 me-3 d-inline-flex align-items-center shadow'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='me-3 dark-icon' />      
        Back to clients
      </Link>
      <div className="card mb-5 mb-xl-8 shadow">
        <div className='card-header border-0 pt-5 mt-3'>
          <h3 className='card-title align-items-start flex-column padding-start-match'>
            <span className='card-label fw-bolder fs-3 mb-1'>Contracts</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Total {contracts.length} contracts</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive mt-3 mb-5 border border-secondary rounded'>
            <table className='table align-middle gs-0 gy-4 m-0'>
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-100px header-match'>Name</th>
                  <th className='min-w-200px header-match'>Address</th>
                  <th className='min-w-200px header-match'>Container URL</th>
                  <th className='min-w-200px header-match'>Owner</th>
                  <th className='min-w-200px header-match'>Network ID</th>
                  <th className='min-w-200px header-match'>Variant</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  !error &&
                  contracts.map((contract, index) => (
                    <tr key={index}>
                      <td className='body-match'>
                        <Link
                          to={`/contract-details/${contract?.id}?clientId=${clientId}`}
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {contract?.name}
                          <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='ms-3 dark-icon' />
                        </Link>
                      </td>
                      <td className='body-match'>
                        <a
                          href={`https://rinkeby.etherscan.io/address/${contract?.address}`}
                          target="_blank"
                          rel="noreferrer"
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {contract?.address}
                          <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                        </a>
                      </td>
                      <td className='body-match'>
                        <a
                          href={contract?.containerUrl}
                          target="_blank"
                          rel="noreferrer"
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {
                            contract?.containerUrl !== '' && (
                              <>
                                {contract?.containerUrl}
                                <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                              </>
                            )
                          }
                        </a>
                      </td>
                      <td className='body-match'>
                        <a
                          href={`https://rinkeby.etherscan.io/address/${contract?.address}`}
                          target="_blank"
                          rel="noreferrer"
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {contract?.owner}
                          <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='ms-3' />
                        </a>
                      </td>
                      <td className='body-match'>
                        <p
                          className='text-dark fw-bolder d-block mb-1 fs-6'
                        >
                          {contract?.networkId}
                        </p>
                      </td>
                      <td className='body-match'>
                        <p
                          className='text-dark fw-bolder d-block mb-1 fs-6'
                        >
                          {contract?.variant}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!loading && error && <div className='text-center text-danger my-6'>{error}</div>}
            {loading && <div className='text-center text-dark my-6'>Loading ...</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

function ContractsWrapper(): JSX.Element {
  let { clientId } = useParams()
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [contracts, setContracts] = useState<ContractModel[]>([])

  useEffect(() => {
    setLoading(true)
    getContractsList(user.email, clientId || '')
      .then((res) => {
        setLoading(false)
        setError('')
        setContracts(res)
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
        console.log(err)
      })
  }, [clientId, user])

  return (
    <>
      <PageTitle breadcrumbs={[]}> Contracts </PageTitle>
      <ContractsPage contracts={contracts} loading={loading} error={error} clientId={clientId} />
    </>
  )
}

export default ContractsWrapper
