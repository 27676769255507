import React, { createContext, useState, useCallback, useEffect } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import WalletConnect from '@walletconnect/web3-provider'

import { getChainData } from './utilities'
import { toast } from 'react-toastify'

const INITIAL_STATE: any = {
  fetching: false,
  address: '',
  web3: null,
  ethers: null,
  provider: null,
  connected: false,
  chainId: 4,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
}

const initWeb3 = (provider) => {
  const web3 = new Web3(provider)

  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        //outputFormatter: web3.utils.hexToNumber
      },
    ],
  })

  return web3
}

export const WalletContext = createContext({
  config: {
    chainId: undefined,
    web3: undefined,
    address: undefined,
    ethers: undefined,
  },
  setConfig: () => {},
  error: null,
  setError: () => {},
  onConnect: () => {},
  resetApp: () => {},
})

export const WalletProvider = ({ children }) => {
  const [config, _setConfig] = useState({ ...INITIAL_STATE })
  const [error, _setError] = useState(null)

  const setConfig: any = useCallback((newConfig) => _setConfig(newConfig), [_setConfig])
  const setError: any = useCallback((newError) => _setError(newError), [_setError])

  const getNetwork = () => {
    try {
      return getChainData(config.chainId).network
    } catch (e) {
      toast.error('Please change your network to one of the chains available and reconnect')
      console.log(e)
    }
  }

  const getProviderOptions = () => {
    const infuraId = process.env.REACT_APP_INFURA_ID
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          infuraId,
        },
      },
    }

    return providerOptions
  }

  const web3Modal = new Web3Modal({
    network: getNetwork(),
    cacheProvider: true,
    providerOptions: getProviderOptions(),
  })

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return
    }

    try {
      provider.on('disconnect', () => resetApp())

      provider.on('accountsChanged', async (accounts) => {
        setConfig((oldConfig) => ({ ...oldConfig, address: accounts[0] }))
      })

      provider.on('chainChanged', async (chainId) => {
        const { web3 } = config
        const networkId = await web3.eth.net.getId()
        setConfig((oldConfig) => ({ ...oldConfig, chainId, networkId }))
      })

      provider.on('networkChanged', async (networkId) => {
        const { web3 } = config
        const chainId = await web3.eth.chainId()
        setConfig((oldConfig) => ({ ...oldConfig, chainId, networkId }))
      })
    } catch (err) {
      console.error(err)
      setConfig((oldConfig) => ({ ...oldConfig, pendingRequest: false, result: null }))
      setError(err)
    }
  }

  const onConnect = async () => {
    try {
      const provider = await web3Modal.connect()

      await subscribeProvider(provider)

      await provider.enable()

      const ethers3 = new ethers.providers.Web3Provider(provider)
      const web3 = initWeb3(provider)

      const accounts = await web3.eth.getAccounts()

      const address = accounts[0]

      const networkId = await web3.eth.net.getId()

      const chainId = await web3.eth.getChainId()

      console.log('accounts', accounts)
      setConfig({
        web3,
        ethers: ethers3,
        provider,
        connected: true,
        address,
        chainId,
        networkId,
      })
    } catch (err) {
      toast.error('Please restart the connection process')
      setConfig((oldConfig) => ({
        ...oldConfig,
        web3: null,
        ethers: null,
        pendingRequest: false,
        result: null,
      }))
      setError(err)
    }
  }

  const resetApp = async () => {
    const { web3 } = config

    try {
      if (web3 && web3.currentProvider && web3.currentProvider.close) {
        await web3.currentProvider.close()
      }
      await web3Modal.clearCachedProvider()
      setConfig({ ...INITIAL_STATE })
    } catch (err) {
      console.error(err)
      setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }))
      setError(err)
    }
  }

  useEffect(() => {
    if (web3Modal.cachedProvider && config.address === '') {
      onConnect().then(() => {
        console.log('Web 3 working')
      })
    }
  })

  return (
    <WalletContext.Provider value={{ config, setConfig, error, setError, onConnect, resetApp }}>
      {children}
    </WalletContext.Provider>
  )
}
