import React, { useContext, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { signFileSignatureToken } from '../../../../helpers/web3'
import { secondaryDb } from '../../../../setup/firebase/firebaseConfig'
import { toast } from 'react-toastify'
import { doc, getDoc } from 'firebase/firestore'

function GenerateFileSignatureTwo(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [name, setName] = useState<string>('')
  const [template, setTemplate] = useState<any>({})
  const [dataId, setDataId] = useState<string>('')
  const [signature, setSignature] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = async () => {
    try {
        if (name !== '' && dataId !== '') {
            setLoading(true)
            console.log('contract address', contractDetails)

            const docRef = doc(secondaryDb, 'templates', dataId)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                setTemplate(docSnap.data())

                const res = await signFileSignatureToken(name, template, dataId, config.chainId, config.web3, config.ethers, contractDetails.address)

                console.log('SIGNING !!!', res)
                setSignature(res)
                setLoading(false)
            }
            else {
                setLoading(false)
                toast.error('Template does not exist')
            }
        }
        else {
            setLoading(false)
            toast.error('Please fill out all fields')
        }
    }
    catch (err) {
        console.log(err)
        setLoading(false)
        toast.error('Error getting file signature')
    }
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card shadow'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <p className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-6'>
              Generate File Signature
            </p>

            <div className='d-flex flex-center flex-wrap mb-5 mw-100'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'>Signature</div>
                <div className='fw-bold text-gray-400'>
                  {signature ? signature : '-'}
                </div>
              </div>
            </div>

            <div className='mb-5 w-75'>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Name'
                className='form-control form-control-lg form-control-solid'
              />
            </div>

            <div className='mb-5 w-75'>
              <input
                value={dataId}
                onChange={(e) => setDataId(e.target.value)}
                placeholder='Data ID'
                className='form-control form-control-lg form-control-solid'
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={getStatus}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Get State'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenerateFileSignatureTwo
