import { UserModel } from '../../app/modules/auth/models/UserModel'

export function firebaseUserMapper(doc: any): UserModel {
  const { uid, displayName, email, emailVerified, phoneNumber } = doc
  return {
    uid,
    displayName,
    email,
    emailVerified,
    phoneNumber,
  }
}
