import React, { useContext, useState } from 'react'
import { WalletContext } from '../../../../helpers/WalletProvider'
import { checkWhitelist, mintStandardReservedToAddress } from '../../../../helpers/web3'
import { toast } from 'react-toastify'

function MintStandardReserved(props) {
  const { contractDetails } = props
  const { config } = useContext(WalletContext)
  const [count, setCount] = useState<string>('')
  const [owner, setOwner] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const getStatus = () => {
    setLoading(true)
    mintStandardReservedToAddress(
      count,
      owner,
      contractDetails.owner,
      config.chainId,
      config.web3,
      contractDetails.address
    )
      .then((res) => {
        setLoading(false)
        toast.success('Mint standard reserved changes successfully')
        setCount('')
        setOwner('')
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error(err.message || 'Error getting check whitelist status')
      })
  }

  return (
    <>
      <div className='col-md-6 col-xxl-4'>
        <div className='card'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-6'>
              Mint standard reserved
            </a>

            <div className='mb-5 w-75'>
              <input
                value={count}
                onChange={(e) => setCount(e.target.value)}
                placeholder='Count'
                className='form-control form-control-lg form-control-solid '
              />
            </div>

            <div className='mb-5 w-75'>
              <input
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                placeholder='Owner'
                className='form-control form-control-lg form-control-solid '
              />
            </div>

            <button
              className={`${loading && 'disabled'} btn btn-sm btn-light-primary fw-bolder`}
              style={{ minWidth: '120px' }}
              onClick={getStatus}
            >
              {loading ? (
                <div className='spinner-grow-sm spinner-border' role='loading'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Set State'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MintStandardReserved
